import React, { Fragment, useState, useEffect } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../components/Layout/styled"

import Footer from "../../components/Layout/Footer"
import { withUserContext } from "../../context/UserContext"
import SeoComp from "../../components/SeoComp"

const EditAccount = ({ intl, data, userContext }) => {
  const {
    profile: { firstName, lastName, email },
    profile,
  } = userContext

  const [fName, setfName] = useState("")
  const [lName, setlName] = useState("")
  const [Email, setEmail] = useState("")

  const setAllStatesInitalValue = () => {
    setfName(firstName ? firstName : "")
    setlName(lastName ? lastName : "")
    setEmail(email ? email : "")
  }

  useEffect(() => {
    setAllStatesInitalValue()
  }, [profile])

  return (
    data &&
    data.allDirectusUserDashboardTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "filleul",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />
        <SectionContainer>
          <SectionInnerWrapper>
            <RowWrapper>
              <ColumnWrapper>
                <RowGroup>
                  <Column className="margin_right">
                    {/* <iframe
                      style={{
                        MaxWidth: "100%",
                        width: "100%",
                        height: "320px",
                        margin: "0px",
                      }}
                      align="middle"
                      frameBorder={0}
                      src="https://www.ref-r.com/campaign_user/p?brandid=62536&campaignid=30861&bid_e=09909CDEB2869813B6584F62B556528C&t=420&email=&fname="
                    ></iframe> */}
                  </Column>
                </RowGroup>
              </ColumnWrapper>
            </RowWrapper>
          </SectionInnerWrapper>
        </SectionContainer>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  max-width: 1080px;
  width: 100%;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  color: #777;

  > h3 {
    font-family: "GothamLight";
    margin-bottom: 0.5em;
    color: #262626;
    font-weight: Lighter;
    font-size: 1em;
    line-height: 1.4em;
  }

  > p {
    color: #777;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 1.3em;
  }
`

const RowGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  margin-bottom: 0.5em;
  flex-direction: row;

  > .margin_left {
    margin-left: 2%;

    @media (max-width: 550px) {
      margin-left: 0%;
    }
  }

  > .margin_right {
    margin-right: 2%;

    @media (max-width: 550px) {
      margin-right: 0%;
    }
  }
`

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  flex: 1;

  @media (max-width: 550px) {
    margin-right: 0%;
    width: 100%;
    flex-basis: 100%;
  }

  > h2 {
    width: 100%;
    font-size: 1em;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px;
    font-family: "GothamLight";
    font-weight: bold;
    text-transform: uppercase;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusUserDashboardTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        first_name_string
        last_name_string
        account_section_info
        email_address_string
        account_display_name_string
        account_password_change_string
        account_current_password_string
        account_confirm_password_string
        account_new_password_string
        account_save_changes_button
        language
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "details_du_compte" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default withUserContext(injectIntl(EditAccount))
